@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');


body, html {
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  margin: 0;
  background-color: #CCC5B7;
}
h1, h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: #6F5D44;
}

h3, h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #6F5D44;
}

button, .cta-text {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

body, p, li {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}l

.App {
  text-align: center;
}

.nav-link.active {
  font-weight: bold;
  color: #007bff !important; /* Cambiar el color del enlace activo */
  border-bottom: 2px solid #007bff; /* Línea inferior para resaltar el activo */
}

.navbar-brand img {
  height: 60px;
  margin: 10px;
}

.nav-link:hover {
  font-weight: 700;
  color: #007bff !important; /* Hacer que el texto se destaque cuando se pasa el ratón */
}

.nav-link {
  margin-right: 15px; /* Aumentar espacio entre los links */
}

.nav-icon {
  color: #36362f; /* Color oscuro para íconos */
  transition: color 0.3s ease; /* Animación para cuando el ícono se destaque */
}

.nav-link {
  transition: all 0.3s ease;
  color: rgb(146, 135, 135);
}

.nav-icon.active{
  color: rgb(146, 135, 135) !important;
  border-bottom: none !important;
}

.nav-icon:hover {
  color: #007bff !important;
  transform: scale(1.25); /* Un pequeño aumento al pasar el ratón */
}

/*Footer*/

.footer {
  bottom: 0;
  width: 100%;
}
