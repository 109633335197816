.container {
  width: 80%;
  margin: auto;
}

h2 {
  text-align: center;
}

.project-table, .task-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.project-row, .task-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 8px;
}

.project-row:hover, .task-row:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.project-row div, .task-row div {
  flex: 1;
  text-align: left;
}

button {
  display: block;
  margin: auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
